<template>
  <div>
    <header class="header">
      <div class="header-col header-col-start">
        <img src="./assets/logo.png" alt="UPSA" class="logo" />
        <h1>Cours interentreprises</h1>
      </div>
      <div class="header-col header-col-end">
        <div class="date">
          {{ date }}
        </div>
        <div class="time">
          {{ time }}
        </div>
      </div>
    </header>
    <Hello />
  </div>
</template>

<script>
import Hello from "./components/HelloWorld.vue";

export default {
  name: "App",
  components: {
    Hello,
  },
  data() {
    return {
      date: "",
      time: "",
    };
  },
  methods: {
    afficherDate() {
      var now = new Date();
      this.date = now.toLocaleString("fr-FR", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "Europe/Zurich",
      });
      this.time = now.toLocaleString("fr-FR", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZone: "Europe/Zurich",
      });
    },
  },
  mounted() {
    this.afficherDate();
    setInterval(this.afficherDate, 1000);
  },
};
</script>

<style>
body {
  margin: 0;
  background-color: #222222;
  color: white;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: normal;
}

.logo {
  height: 64px;
  width: auto;
}

.header {
  display: flex;
  align-items: center;
  padding: 8px 32px;
  background-color: #323232;
  color: white;
}

.header-col {
  display: flex;
  align-items: center;
  height: 100%;
  line-height: 1;
  width: 50%;
  font-size: 28px;
}

.header-col h1 {
  font-size: 24px;
  font-weight: normal;
  line-height: 1;
  margin: 0 0 0 16px;
}

.header-col-start {
  align-items: center;
}

.header-col-end {
  align-items: end;
  justify-content: flex-end;
  gap: 8px;
}

.time {
  color: #cccccc;
}

@media screen and (max-width: 1200px) {
  .logo {
    height: 48px;
  }

  .header {
    padding: 0px 12px;
  }

  .header-col h1 {
    font-size: 16px;
    margin-top: 0;
  }

  .header-col {
    font-size: 16px;
  }
}
</style>
