<template>
  <div class="container">
    <div class="table-header">
      <div class="header-item">Ateliers</div>
      <div class="header-item">Actuellement</div>
      <div class="header-item">Prochainement</div>
    </div>
    <div v-for="(events, roomName) in rooms" :key="roomName" class="room">
      <div
        class="room-status"
        :class="{
          'room-status-occupied': events.currentEvent,
          'room-status-free': !events.currentEvent,
        }"
      ></div>
      <div class="room-content">
        <div class="room-name">{{ roomName }}</div>
        <div class="room-current">
          <div class="tagline">Actuellement</div>
          <div class="room-event">
            {{ events.currentEvent ? events.currentEvent.summary : "Libre" }}
          </div>
          <div class="room-time">
            {{
              events.nextEvent && !events.currentEvent
                ? formatDateEnd(events.nextEvent.start)
                : events.currentEvent
                ? formatDateEnd(events.currentEvent.end)
                : ""
            }}
          </div>
        </div>
        <div class="room-next">
          <div class="tagline">Prochainement</div>
          <div class="room-event">
            {{ events.nextEvent && events.nextEvent.summary }}
          </div>
          <div class="room-time">
            {{ events.nextEvent && formatDateStart(events.nextEvent.start) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "TheWelcome",
  data() {
    return {
      rooms: [],
      today: new Date(
        new Date().toLocaleString("fr-FR", { timeZone: "Europe/Zurich" })
      ),
    };
  },
  created() {
    this.fetchRooms();
    setInterval(() => {
      if (moment().minutes() % 30 === 0) {
        this.fetchRooms();
      }
    }, 1 * 60 * 1000);
  },
  methods: {
    fetchRooms() {
      axios
        .get("https://online-calendar.jcloud.ik-server.com/")
        .then((response) => {
          this.rooms = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    isNow(start, end) {
      return moment().isBetween(start, end);
    },
    formatDateEnd(eventDate) {
      let now = new Date(
        new Date().toLocaleString("fr-FR", { timeZone: "Europe/Zurich" })
      );
      let event = new Date(eventDate);

      // texte pour les événements qui finissent aujourd'hui
      if (now.toDateString() === event.toDateString()) {
        return (
          "Jusqu'à " +
          event.toLocaleString("fr-FR", {
            hour: "2-digit",
            minute: "2-digit",
            // second: '2-digit'
          })
        );
      } else {
        return "Toute la journée";
      }
    },
    formatDateStart(eventDate) {
      let now = new Date(
        new Date().toLocaleString("fr-FR", { timeZone: "Europe/Zurich" })
      );
      let event = new Date(eventDate);

      // texte pour les événements qui finissent aujourd'hui
      if (now.toDateString() === event.toDateString()) {
        return (
          "Aujourd'hui, à " +
          event.toLocaleString("fr-FR", {
            hour: "2-digit",
            minute: "2-digit",
            // second: '2-digit'
          })
        );
      } else {
        // texte pour les événements qui finissent un autre jour
        return event.toLocaleString("fr-FR", {
          weekday: "long",
          month: "long",
          day: "numeric",
        });
      }
    },
  },
};
</script>

<style scoped>
.tagline {
  display: none;
}

.container {
  padding: 16px 32px;
}

.table-header {
  display: flex;
  padding: 16px 16px 16px 24px;
  font-size: 20px;
  color: #888888;
}

.header-item {
  width: 33%;
}

.room {
  position: relative;
  display: flex;
  padding: 16px 24px;
  margin-bottom: 16px;
  overflow: hidden;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.04);
}

.room-status {
  position: absolute;
  height: 100%;
  width: 8px;
  left: 0;
  top: 0;
}

.room-status-occupied {
  background-color: #f56565;
}

.room-status-free {
  background-color: #48bb78;
}

.room-current,
.room-next,
.room-name {
  width: 33%;
}

.room-content {
  text-align: left;
  display: flex;
  width: 100%;
}

.room-time {
  display: inline-block;
  margin-top: 8px;
  color: #11e489;
}

.room-name {
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
}

.room-event {
  font-size: 20px;
}

@media screen and (max-width: 1200px) {
  .tagline {
    font-size: 12px;
    color: #888888;
    font-weight: light;
    margin-bottom: 2px;
  }

  .table-header {
    padding: 4px 16px 8px 16px;
    font-size: 10px;
  }

  .room {
    margin-bottom: 4px;
    padding: 8px 16px 8px 24px;
  }

  .room-time {
    font-size: 10px;
    margin: 0;
  }

  .header-item {
    font-size: 12px;
  }

  .room-name {
    font-size: 16px;
  }

  .room-event {
    font-size: 12px;
    display: inline;
    margin: 0 4px 0 0;
  }

  .container {
    padding: 8px 8px;
  }
}
</style>
